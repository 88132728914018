@import "../../global.scss";

.bottombar {
  background-color: $iceBlue;
  color: $mainColor;
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0px;
  @include mobile {
    bottom: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .wrapper {
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    @include mobile {
      overflow: hidden;
    }

    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      float: left;
      .logo1 {
        font-size: 40px;
        text-decoration: none;
        color: inherit;
      }
      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;
        @include mobile {
          margin-left: 10px;
        }
        .icon1 {
          margin-top: 5px;
          font-size: 40px;
          @include mobile {
            padding-right: 20px;
          }
        }
        span {
          font-size: 20px;
          font-weight: 500;

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }
    .right{
      width: 40%;
      display: flex;
      text-align: center;
      color: $mainColor;
      align-items: flex-end;
      margin-right: 80px;
      position: absolute;
      right: 0;
      top: 25px;
      @include mobile{
        overflow: hidden;
        font-size: 15px;
        align-items: center;
        position: absolute;
        left: 200px;
        top: 15px;
      }
      span{
        @include mobile{
          overflow: hidden;
        } 
      }
    }
  }
}
