@import "../../global.scss";

.topbar {
  background-color: $brickRed;
  color: $mainColor;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
  overflow: hidden;

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  img {
    height: 3.5vw;
    width: auto;
    margin-left: 20px;

    @include mobile {
      width: 130px;
      margin-left: 0px;
      height: auto;
    }
    @media screen and (max-width: 410px) {
      width: 100px;
    }
  }

  .wrapper {
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      .logo {
        font-size: 40px;
        text-decoration: none;
        color: inherit;
        margin-right: 20px;
        transition: 0.15s ease;
        @include mobile {
          margin-right: 10px;
          margin-top: 5px;
          position: relative;
        }
        &:hover {
          color: $secondaryColor;
        }
      }
    }
    .middle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include mobile {
        display: none;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        margin-right: 6vw;
      }
      a {
        text-decoration: none;
        padding-right: 30px;
        padding-left: 30px;
        font-size: 1.5vw;
        font-weight: 500;
        color: inherit;
        transition: 0.15s ease;
        &:hover {
          color: $secondaryColor;
        }
      }
    }
    .button1 {
      display: flex;
      justify-content: center;
      margin-right: 15px;
      padding: 0.35em 1.2em;
      border: 0.1em solid #ffffff;
      border-radius: 0.12em;
      box-sizing: border-box;
      text-decoration: none;
      float: left;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      color: #ffffff;
      text-align: center;
      transition: all 1s ease;
    }
    .button1:hover {
      color: $brickRed;
      background-color: #ffffff;
    }
    @media all and (max-width: 30em) {
      a.button1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 150px;
        overflow: hidden;
        @media screen and (max-width: 410px) {
          width: 100px;
          font-size: 10px;
          height: 30px;
        }
      }
    }
    .right {
      display: flex;
      justify-content: center;

      @include mobile {
        flex: 1;
        flex-direction: row;
      }
      .hamburger {
        width: 32px;
        margin-top: 4px;
        margin-left: 33px;
        padding-left: 10px;
        height: 25px;
        float: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        @include mobile {
          margin-top: 5px;
          position: absolute;
          right: 15px;
        }
        @media screen and (max-width: 410px) {
          top: 18px;
        }

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 1s ease;
        }
      }
    }
  }

  &.active {
    background-color: $iceBlue;
    color: $mainColor;

    .left {
      .logo {
        &:hover {
          color: $mainColor;
        }
      }
    }

    .middle {
      a {
        &:hover {
          color: $mainColor;
        }
      }
    }

    .button1 {
      overflow: auto;
      padding: 0.35em 1.2em;
      border: 0.1em solid $mainColor;
      border-radius: 0.12em;
      box-sizing: border-box;
      text-decoration: none;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      float: right;
      color: $mainColor;
      text-align: center;
      transition: all 1s ease;
    }
    .button1:hover {
      color: $iceBlue;
      background-color: $mainColor;
    }
    @media all and (max-width: 30em) {
      a.button1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 150px;
        overflow: hidden;
      }
    }
    .hamburger {
      span {
        &:first-child {
          background-color: $mainColor;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: $mainColor;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
