@import "../../global.scss";

.swal-button {
  color: $mainColor;
  background-color: $secondaryColor;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $mainColor;
    color: $secondaryColor;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}
.swal-modal {
  background-color: $mainColor;
  border: 3px solid $secondaryColor;
  overflow: hidden;
}

.swal-title {
  color: $secondaryColor;
}

.swal-text {
  color: $secondaryColor;
}

.contact {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 0.7;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      flex: 0.8;
    }
    img {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mobile {
        height: auto;
        width: 100%;
        margin-left: 14px;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $secondaryColor;
    width: 100%;
    margin-bottom: 100px;
    h2 {
      font-size: 30px;
      padding-bottom: 30px;
      overflow: hidden;
    }
    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile {
        position: relative;
      }

      input {
        width: 500px;
        height: 50px;
        font-size: 14px;
        margin-top: 10px;

        @include mobile {
          width: 80vw;
          height: 30px;
          margin-bottom: 0px;
        }
      }
      textarea {
        width: 500px;
        height: 200px;
        font-size: 14px;
        margin-top: 10px;

        @include mobile {
          width: 80vw;
          height: 100px;
          margin-top: 10px;
        }
      }

      button {
        width: 150px;
        height: 40px;
        color: $mainColor;
        background-color: $secondaryColor;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        margin-top: 10px;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
      span {
        color: $brickRed;
      }
    }
  }
}
