@import "../../global.scss";

.menu {
  width: 300px;
  height: 100vh;
  background-color: $iceBlue;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  &.active {
    right: 0;
  }

  .AirExpress {
    position: absolute;
    color: $mainColor;
    top: 80px;
    font-size: 18px;
    font-weight: 500;
  }
  .hrTop {
    position: absolute;
    top: 110px;
    width: 90%;
    margin: 0.5em 0 0.5em 0;
    height: 2px;
    background-color: $mainColor;
    border: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    color: #000;
    width: 100%;
    text-align: center;
  }

  .contact {
    background-color: $iceBlue;
  }

  li {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .icon {
      padding-right: 10px;
      color: $mainColor;
    }
    a {
      font-size: inherit;
      color: inherit;
      text-decoration: none;
      &:hover {
        font-weight: 800;
      }
    }
    span {
      font-weight: 500;
      font-size: 20px;
      color: $mainColor;
    }
  }
  .socials {
    position: absolute;
    bottom: 0;
    .logo {
      color: $mainColor;
      text-decoration: none;
      margin-left: 20px;
    }
    .icon1 {
      font-size: 50px;
    }
  }
}
