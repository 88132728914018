@import "../../global.scss";

.services {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  h1 {
    display: flex;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    align-items: center;
    color: $secondaryColor;
    font-size: 50px;
    @include mobile {
      font-size: 40px;
      position: static;
      transform: none;
    }
  }
  div{
    @include mobile{
      overflow: hidden;
      position: static;
      transform: none;

    }
  }
  
  .header{
    @include mobile{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }

  .middle {
    display: flex;
    align-items: center;
    margin: 10px;
    padding-bottom: 0.5em;
    position: absolute;
    top: 100px;
    @include mobile {
      position: static;
      display: block;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0px;
    }
    h3 {
      font-size: 2vw;
      color: $secondaryColor;
      border: 2px;
      padding: 5px;
      border-style: solid;
      border-color: $secondaryColor;
      @include mobile {
        font-size: 30px;
      }
    }
  }

  .arrow {
    height: 50px;
    position: absolute;

    @include mobile {
      display: none;
    }
    &.left {
      left: 50px;
      bottom: 250px;
      cursor: pointer;
    }
    &.right {
      right: 50px;
      bottom: 250px;
      cursor: pointer;
    }
  }

  .slider {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 25px;
    transition: all 1s ease-out;

    @include mobile {
      position: static;
      height: 70vh;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile{
        height: 150px;
        margin-bottom: 15px;
        overflow: hidden;
      }

      .item {
        width: 900px;
        height: 100%;
        background-color: $secondaryColor;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 80%;
          height: 150px;
          margin: 15px 0;
        }

        .left {
          flex: 4;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $mainColor;
            @include mobile{
              width: 100%;
            }

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $mainColor;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              @include mobile {
                width: 30px;
                height: 30px;
              }

              a {
                font-size: 40px;
                text-decoration: none;
                color: $secondaryColor;
                margin-right: 20px;
              }
              a:visited {
                color: $secondaryColor;
                text-decoration: none;
              }
            }
            h2 {
              font-size: 40px;
              @include mobile {
                font-size: 20px;
              }
            }
            p {
              font-size: 20px;
              @include mobile {
                display: none;
              }
            }
            span {
              font-size: 18px;
              font-weight: 600;
              text-decoration: underline;
              color: $secondaryColor;
              cursor: pointer;
              @include mobile{
                font-size: 12px;
              }
            }
            .quote{
              color: $brickRed;
            }
          }
        }
        .right {
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 30px;
          overflow: hidden;
          @include mobile{
            flex: 4;
            margin-right: 20px;
          }

          img {
            width: 400px;
            height: 400px;
            border-radius: 20px;
            @include mobile {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }
  }
}
