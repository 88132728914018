@import "../../global.scss";

.home {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 1s ease;

  @include mobile {
    height: 70%;
    flex-direction: column;
    align-items: center;
  }
  .top {
    overflow: hidden;
    position: relative;
    flex: 0.6;

    @include mobile {
      flex: 0.95;
    }
    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 1s ease;
      &.active {
        position: relative;
        right: 150px;
      }

      @include mobile {
        position: relative;
        text-align: center;
        justify-content: start;
        top: 40px;
        &.active {
          position: relative;
          right: 0px;
        }
      }
      hr {
        margin: 0.5em 0 0.5em 0;
        width: 60%;
        height: 2px;
        background-color: $iceBlue;
        border: none;
        @include mobile {
          margin-left: 0px;
          width: 90%;
        }
      }
      h1 {
        font-size: 5vw;
        margin: 10px;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        color: $brickRed;

        @include mobile {
          font-size: 30px;
        }
        @media screen and (max-height: 780px) {
          padding-bottom: 0em;
          padding-top: 0em;
        }
      }
    }
  }
  .middle {
    display: flex;
    align-items: center;
    margin: 10px;
    padding-bottom: 0.5em;
    padding-top: 3em;

    @include mobile {
      display: inline;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    @media screen and (max-height: 780px) {
      padding-bottom: 0em;
      padding-top: 0em;
    }

    .icon {
      font-size: 1vw;
      color: $brickRed;
      margin-right: 15px;
      margin-left: 15px;
      @include mobile {
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    h3 {
      font-size: 2vw;
      color: $brickRed;
      @include mobile {
        font-size: 30px;
      }
    }
  }
  .bottom {
    flex: 0.4;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 0;
    background-color: $brickRed;
    align-items: center;
    width: 100%;
    height: 30%;
    transition: all 1s ease;
    @include mobile {
      bottom: 0px;
    }
    &.active {
      background-color: $iceBlue;
    }
    &.active {
      width: 80%;
      @include mobile {
        width: 100%;
      }
      span {
        transition: all 1s ease;
        color: $mainColor;
      }
    }
    span {
      font-size: 3.5vw;
      color: $mainColor;
      overflow: hidden;
      transition: all 1s ease;
      @include mobile {
        font-size: 10vw;
        text-align: center;
      }
    }
  }
}
