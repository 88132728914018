@import "../../global.scss";

.brands {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    flex: 1;
    @include mobile {
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      align-items: center;
    }
    .left {
      flex: 1;
      display: flex;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      overflow: hidden;
      margin-right: 40px;
      transition: all 1s ease;
      @include mobile {
        flex-direction: row;
        margin-right: 0px;
        padding-left: 0px;
      }
      .container {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          align-items: center;
          margin-bottom: 30px;
        }

        .header {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          h1 {
            font-size: 50px;
            padding-top: 40px;
            color: $secondaryColor;
            overflow: hidden;

            @include mobile {
              padding-top: 0px;
              font-size: 40px;
              display: flex;
            }
            @media screen and (max-height: 780px) {
              font-size: 30px;
            }
          }
        }

        .text {
          padding: 10px;
          border-radius: 10px;
          background-color: $mainColor;
          color: $secondaryColor;
          text-align: center;
          @include mobile {
            font-size: 14px;
            padding: 0 5px 0 5px;
            overflow: hidden;
            position: relative;
          }
          @media screen and (max-height: 780px) {
            font-size: 12px;
          }
        }
      }
      img {
        width: 90%;
        height: auto;
        border-radius: 15px;
        margin-bottom: 30px;
        border-color: black;
        border-width: 2px;
        border-style: solid;
        margin-top: 40px;
        @include mobile {
          height: auto;
          width: 70%;
          border-radius: 15px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        @media screen and (max-height: 780px) {
          width: 45%;
        }
      }
    }
    .right {
      flex: 0.7;
      display: flex;
      height: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      overflow: hidden;
      transition: all 1s ease;
      @include mobile {
        flex: 1;
        flex-direction: row;
        padding: 0px;
        height: 250px;
        margin: 0;
      }
      .container {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          height: 100%;
        }

        .header {
          width: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h1 {
            font-size: 50px;
            color: $secondaryColor;
            padding-top: 40px;

            @include mobile {
              position: static;
              overflow: hidden;
              display: flex;
              font-size: 40px;
              padding-top: 0px;
            }
            @media screen and (max-height: 780px) {
              font-size: 30px;
            }
          }
        }

        .text {
          padding: 10px;
          border-radius: 10px;
          background-color: $mainColor;
          color: $secondaryColor;
          text-align: center;
          @include mobile {
            position: relative;
            display: block;
            font-size: 14px;
            margin: 20px 0px;
            padding: 0 5px 10px 5px;
            overflow: hidden;
          }
          @media screen and (max-height: 780px) {
            font-size: 12px;
          }
        }
        a {
          text-decoration: none;
          color: $brickRed;
        }
      }
      img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        border-color: black;
        border-width: 2px;
        border-style: solid;
        height: auto;
        border-radius: 15px;
        margin-right: 0;
        margin-top: 40px;
        @include mobile {
          margin-top: 0px;
          width: 65%;
          border-radius: 15px;
          position: relative;
        }
        @media screen and (max-height: 780px) {
          width: 45%;
        }
      }
    }
  }
}
