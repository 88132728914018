$mainColor:#effcff;
$secondaryColor: #2E2E3A;
$brickRed: #3863a4;
$iceBlue: #b32b26;

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content 
    }
}