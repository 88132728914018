@import "../../global.scss";

.aboutUs {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include mobile {
    overflow: hidden;
  }

  h1 {
    padding-top: 50px;
    padding-bottom: 25px;
    font-size: 50px;
    color: $secondaryColor;
    @include mobile {
      font-size: 40px;
      text-align: center;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    width: 100%;
    flex: 1;
    @include mobile {
      flex-direction: column;
    }
    .left {
      flex: 1;
      min-width: 20%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        flex: 0.4;
        height: 70%;
      }
      img {
        min-width: 55%;
        border-radius: 15px;
        border: 2px solid $secondaryColor;
        margin-left: 10px;
        max-height: 400px;
        @include mobile {
          margin-left: 0px;
          height: auto;
          width: 90%;
          border-radius: 15px;
        }
        @media screen and (max-height: 772px) {
          width: 60%;
          height: auto;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      width: 80%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      margin-right: 40px;
      margin-left: 40px;
      transition: all 1s ease;
      @include mobile {
        align-items: center;
        flex: 0.6;
        width: 100%;
        margin: 0px;
      }
      .text {
        padding: 10px;
        border-radius: 10px;
        background-color: $mainColor;
        color: $secondaryColor;
        font-size: 18px;
        min-width: 75%;
        font-style: italic;
        h2 {
          font-size: 2.3vw;
          @include mobile {
            font-size: 5vw;
          }
        }
        @include mobile {
          font-size: 1em;
          padding: 10px;
          padding-top: 0;
          overflow: hidden;
        }
        @media screen and (max-height: 770px) {
          font-size: 14px;
        }
      }
    }
  }
  .signature {
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    color: $brickRed;
    @include mobile {
      font-size: 14px;
      padding: 5px;
      overflow: hidden;
    }
    h2 {
      float: right;
      padding-right: 20px;
    }
  }
}
