@import "../../global.scss";

.reviews {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include mobile {
    justify-content: space-around;
    height: 100%;
  }

  .mobile {
    display: none;
    @include mobile {
      display: block;
      color: $secondaryColor;
      font-style: italic;
      font-size: 2vh;
      font-weight: 500;
    }

    .review {
      @include mobile {
        border-radius: 15px;
        border: solid $brickRed;
        padding: 20px;
        margin-bottom: 20px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  h1 {
    font-size: 50px;
    color: $secondaryColor;

    @include mobile {
      font-size: 50px;
      position: relative;
      top: 30px;
    }
  }

  .container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      display: none;
      height: 85%;
      flex-direction: column;
    }

    .card {
      width: 300px;
      position: relative;
      background-color: $mainColor;
      height: 75%;
      border-radius: 10px;
      border: solid $brickRed;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;

      @include mobile {
        display: none;
        position: relative;
        overflow: hidden;
        top: 10px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
        box-shadow: none;
        width: 330px;
        height: 80%;
      }
      &.featured{
        width: 300px;
        height: 80%;
        margin: 0 30px;

        @include mobile{
            width: 250px;
            height: 180;
            margin: 1px;
        }
    }

      &:hover {
        transform: scale(1.1);
        @include mobile {
          transform: none;
        }
      }
      .center {
        padding: 10px;
        border-radius: 10px;
        border: solid $mainColor;
        background-color: $mainColor;
        color: $secondaryColor;
        font-style: italic;
        font-size: 18px;
        @include mobile {
          font-size: 12px;
          padding: 5px;
          overflow: hidden;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        bottom: 10px;
        right: 0;
        margin-right: 10px;
        @include mobile {
          text-align: center;
        }
        h3 {
          margin-bottom: 5px;
          color: $secondaryColor;
          @include mobile {
            font-size: 14px;
            margin-bottom: 0;
            overflow: hidden;
          }
        }
        h4 {
          color: $iceBlue;
          @include mobile {
            font-size: 13px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
